
import { Vue, Component, Prop } from 'vue-property-decorator';
import HomepageSettingForm from '@/components/homepage/setting.vue';
import { IMyHomepageDetail } from '@/types';

@Component({
  name: 'HomepageSetting',
  components: {
    HomepageSettingForm,
  },
})
export default class extends Vue {
  @Prop({ required: true }) detail!: IMyHomepageDetail
}
