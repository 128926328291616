
import { getFile, uploadFile } from '@/api/attachedFile';
import { updateHomepage } from '@/api/myHomepage';
import { UserModule } from '@/store/user';
import { IMyHomepageDetail, IMyHomepageUpdate } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageSettingForm',
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  mounted() {
    if (this.detail.userDetail.userId !== UserModule.userId) {
      alert('권한이 없습니다.');
      this.$router.push({ name: 'HomepageIndex', params: { nickname: this.$route.params.nickname } });
    } else {
      this.formData = {
        ...this.detail,
      };
      if (this.detail.userDetail.logoFileUid) {
        getFile(this.detail.userDetail.logoFileUid).then((res) => {
          this.logoFileName = res.data.originalName;
        });
      }
    }
  }

  private formData: IMyHomepageUpdate = {
    skinType: 0,
    logoFileUid: '',
    menuState: false,
    introduceState: false,
    noticeState: false,
    interestPostState: false,
    productState: false,
    inquireState: false,
  };

  private logoFileName = '';

  private handleClickCancle() {
    this.$router.push({ name: 'HomepageIndex', params: { nickname: this.$route.params.nickname } });
  }

  private handleClickSkin(skinType: number) {
    this.formData.skinType = skinType;
  }

  private handleClickSave() {
    updateHomepage(this.formData).then(() => {
      alert('저장되었습니다.');
      window.location.reload();
    });
  }

  private handleChangeFile(e: any) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    uploadFile('client/my-homepage', formData).then((res) => {
      this.formData.logoFileUid = res.data.uid;
      this.logoFileName = res.data.originalName;
    });
  }

  private handleClickRemoveLogo() {
    this.formData.logoFileUid = '';
    this.logoFileName = '';
  }

  private getTabClass() {
    if (this.detail.skinType === 1) return 'mypage_set_tab02';
    return 'mypage_set_tab';
  }

  private getImageClass() {
    if (this.detail.skinType === 1) return 'tabtt_bg_02';
    return 'tabtt_bg';
  }
}
